import { useState, useContext, useEffect } from 'react';
import ListItem from '../../components/ListItem/ListItem';
import AddAthlete from './AddAthlete';
import { TrainerContext, CurrentUserContext } from '../../App';
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal,
} from '@ionic/react';
import { addNewAthleteToTrainers } from '../../helpers/api';
import { add } from 'ionicons/icons';
import Loader from '../../components/Loader/Loader';

import './Athletes.scss';

const Athletes = ({ userId }) => {
  const [athletes, setAthletes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const trainersList = useContext(TrainerContext);
  const currentUser = useContext(CurrentUserContext);

  useEffect(() => {
    setAthletes(trainersList);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [trainersList]);

  const handleAddAthlete = (newAthlete) => {
    if (athletes) {
      setAthletes([...athletes, newAthlete]);
    } else {
      setAthletes(newAthlete);
    }

    addNewAthleteToTrainers(newAthlete, currentUser.uid);
  };

  return (
    <IonPage id='athletes-page'>
      <IonContent fullscreen>
        {isLoading && (
          <div className='placeholder'>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <>
            <IonModal isOpen={isOpen}>
              <AddAthlete
                setIsOpen={setIsOpen}
                handleAddAthlete={handleAddAthlete}
              />
            </IonModal>

            {athletes && (
              <IonHeader mode='ios' collapse='condense'>
                <IonToolbar>
                  <IonTitle size='large'>Athletes</IonTitle>
                  <IonButtons slot='primary'>
                    <IonButton onClick={() => setIsOpen(true)}>
                      <IonIcon slot='icon-only' icon={add} /> Add
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
            )}

            <IonList>
              {athletes &&
                athletes.map((d) => <ListItem key={d.id} item={d} />)}
            </IonList>

            {!athletes && (
              <div className='placeholder'>
                <h1>
                  <em>f</em>v
                </h1>
                <h2>Welcome to FitVerse!</h2>
                <IonButton onClick={() => setIsOpen(true)}>
                  Add an Athlete
                </IonButton>
              </div>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Athletes;
