import React, { useState, useEffect, useContext } from 'react';
import { getFormattedDate, workoutTypes } from '../../helpers/helpers';
import AddWorkoutSection from './AddWorkoutSection';
import { trash } from 'ionicons/icons';
import { TrainerContext } from '../../App';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonToolbar,
  IonList,
  IonInput,
  IonDatetime,
  IonButton,
  IonTextarea,
  IonTitle,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';

const section = {
  id: '',
  type: '',
  data: [],
};

export const AddWorkout = ({
  setAddWorkoutIsOpen,
  user,
  handleAddWorkout,
  workoutEdit,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const [toggledDatePicker, setToggledDatePicker] = useState(false);
  const data = useContext(TrainerContext);

  const [workoutItem, setWorkoutItem] = useState(null);
  const [workoutArray, setWorkoutArray] = useState([]);
  const [extraFields, setExtraFields] = useState([]);
  const [note, setNote] = useState('');
  const [type, setType] = useState(workoutTypes[0]);
  const [sections, setSections] = useState([]);
  const [athleteId, setAthleteId] = useState(user.id);

  //New Code
  useEffect(() => {
    setWorkoutItem(type.fields);
    setWorkoutArray([type.fields]);
    setExtraFields(type.extraFields);
  }, [type]);

  const addNewSection = () => {
    setSections([...sections, { ...section, id: sections.length }]);
  };

  const handleSave = () => {
    handleAddWorkout(selectedDate, note, sections, workoutEdit, athleteId);
    if (setAddWorkoutIsOpen) setAddWorkoutIsOpen(false);
  };

  const removeSet = (id) => {
    const newSetArray = sections.filter((s) => s.id !== id);
    setSections(newSetArray);
  };

  const handleUpdateSetData = (setId, updatedData) => {
    const updatedSections = sections.map((s) => {
      if (s.id === setId) {
        return { ...s, ...updatedData };
      } else {
        return s;
      }
    });
    setSections(updatedSections);
  };

  useEffect(() => {
    if (workoutEdit) {
      setSections(workoutEdit?.data);
      setSelectedDate(workoutEdit?.date);
      setNote(workoutEdit?.note);
    } else {
      setSections([{ ...section, id: 0 }]);
    }
  }, []);

  return (
    <>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>
            {user?.firstName} {user?.lastName}
          </IonTitle>
          <IonButtons slot="start">
            <IonButton
              color="medium"
              onClick={() => {
                if (setAddWorkoutIsOpen) setAddWorkoutIsOpen(false);
              }}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={() => handleSave()}>
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="add-workout">
          <h1>
            {workoutEdit ? (workoutEdit.isClone ? 'Clone' : 'Edit') : 'Add'}{' '}
            Workout
          </h1>
          <IonList lines="none">
            <IonItem>
              <IonLabel>Athlete</IonLabel>
              <IonSelect
                className="wider"
                interface="popover"
                placeholder="Select Athlete"
                onIonChange={(e) => setAthleteId(e.target.value)}
                value={athleteId}
              >
                {data.map((t) => (
                  <IonSelectOption key={t.id} value={t.id}>
                    {t.firstName} {t.lastName}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem onClick={() => setToggledDatePicker(true)}>
              <IonLabel>Date</IonLabel>
              <IonInput value={getFormattedDate(selectedDate)}></IonInput>
            </IonItem>
            {toggledDatePicker && (
              <IonItem>
                <IonDatetime
                  displayFormat="MMMM"
                  value={selectedDate}
                  presentation="date"
                  onIonChange={(e) => {
                    setSelectedDate(e.detail.value);
                    setToggledDatePicker(false);
                  }}
                ></IonDatetime>
              </IonItem>
            )}
          </IonList>
          <h1>
            Sets
            <IonButtons slot="primary">
              <IonButton onClick={() => addNewSection()}>Add Set</IonButton>
            </IonButtons>
          </h1>
          {sections?.map((s, index) => (
            <div key={`s-${index}`} className="workout-section">
              {sections.length > 1 && (
                <h6 className="flex">
                  <span className="faded">Set {index + 1}</span>
                  <IonIcon
                    slot="icon-only"
                    color="danger"
                    icon={trash}
                    onClick={() => removeSet(s.id)}
                  />
                </h6>
              )}
              <AddWorkoutSection
                setId={s.id}
                handleUpdateSetData={handleUpdateSetData}
                workoutEditItem={workoutEdit ? s : null}
              />
            </div>
          ))}
          <h1>Add Notes</h1>
          <IonList className="note">
            <IonItem>
              <IonTextarea
                autocapitalize="true"
                value={note}
                rows="5"
                placeholder="Add notes..."
                onIonChange={(e) => setNote(e.target.value)}
              ></IonTextarea>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </>
  );
};

export default AddWorkout;
