import { useState } from 'react';
import {
  IonPage,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonToast,
  useIonRouter,
} from '@ionic/react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import { addTrainer } from '../../helpers/api';
import './Login.scss';

const Membership = () => {
  const auth = getAuth();
  const router = useIonRouter();
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [password, setPassword] = useState('');

  const registerUser = () => {
    if (email.length < 3 || password.length < 3) return;
    const id = uuidv4();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        addTrainer(
          userCredential.uid || id,
          firstName,
          lastName,
          email,
          cellphone
        );
        // Signed in

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMessage(errorMessage);
      });
  };

  return (
    <IonPage className="login membership">
      <IonToast
        isOpen={errorMessage?.length > 0}
        onDidDismiss={() => setErrorMessage('')}
        message={errorMessage}
        duration={3000}
        color="danger"
        cssClass="toast"
      />
      <div className="overlay">
        <h1>
          <em>f</em>v
        </h1>
        <h2>Trainer Signup</h2>
        <p>
          On Fitverse, Trainers can create and share customized workouts for
          their clients. Because you're an early adoptor, get Fitverse for free
          for a year (a $199 value).
        </p>

        <div>
          <div className="form">
            <IonList>
              <IonItem>
                <IonInput
                  className="wider"
                  value={email}
                  placeholder="Email *"
                  type="email"
                  onIonChange={(e) => setEmail(e.target.value)}
                />
              </IonItem>
              <IonItem>
                <IonInput
                  className="wider"
                  value={password}
                  placeholder="Create Password *"
                  type="text"
                  onIonChange={(e) => setPassword(e.target.value)}
                />
              </IonItem>
              <IonItem>
                <IonInput
                  className="wider"
                  value={firstName}
                  placeholder="First Name"
                  type=""
                  onIonChange={(e) => setFirstName(e.target.value)}
                />
              </IonItem>
              <IonItem>
                <IonInput
                  className="wider"
                  value={lastName}
                  placeholder="Last Name"
                  type=""
                  onIonChange={(e) => setLastName(e.target.value)}
                />
              </IonItem>

              <IonItem>
                <IonInput
                  className="wider"
                  value={cellphone}
                  type="numeric"
                  placeholder="Cellphone"
                  onIonChange={(e) => setCellphone(e.target.value)}
                />
              </IonItem>
            </IonList>
            <div>
              <IonButton expand="full" onClick={() => registerUser()}>
                Register
              </IonButton>
            </div>
            <div
              className="link"
              onClick={() => router.push('/login', 'forward', 'push')}
            >
              Back to Login
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Membership;
