import { useState } from 'react';
import {
  IonPage,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonToast,
  useIonRouter,
} from '@ionic/react';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import './Login.scss';
import { fire, fireAuth } from '../../helpers/fire';

const Login = ({ setIsLoggedIn, setCurrentUser }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const router = useIonRouter();
  //const auth = getAuth(fire);
  const provider = new GoogleAuthProvider();

  const signInWithGoogle = () => {
    console.log('auth', fireAuth);
    console.log('provider', provider);
    signInWithPopup(fireAuth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        // The signed-in user info.
        setIsLoggedIn(true);
        setCurrentUser(user);
        router.push('/', 'forward', 'push');
      })
      .catch((error) => {
        console.log(
          'error',
          error,
          GoogleAuthProvider.credentialFromError(error)
        );
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  setTimeout(() => {
    setIsLoaded(true);
  }, 1500);

  const loginUser = () => {
    signInWithEmailAndPassword(
      //auth,
      fireAuth,
      email,
      password,
      setIsLoggedIn,
      setCurrentUser
    )
      .then((userCredential) => {
        const user = userCredential.user;

        router.push('/', 'forward', 'push');
        setIsLoggedIn(true);
        setCurrentUser(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMessage('Incorrect Username and Password');
      });
  };

  return (
    <IonPage className="login">
      <IonToast
        isOpen={errorMessage.length > 0}
        onDidDismiss={() => setErrorMessage('')}
        message={errorMessage}
        duration={3000}
        color="danger"
        cssClass="toast"
      />
      <div className="overlay">
        <h1>
          <em>f</em>v
        </h1>
        <div className={`content ${isLoaded ? '' : 'hide'}`}>
          <div className="form">
            <IonList>
              <IonItem>
                <IonInput
                  autofocus="true"
                  className="wider"
                  value={email}
                  placeholder="Email"
                  type="email"
                  onIonChange={(e) => setEmail(e.target.value)}
                />
              </IonItem>

              <IonItem>
                <IonInput
                  className="wider"
                  value={password}
                  type="password"
                  placeholder="Password"
                  onIonChange={(e) => setPassword(e.target.value)}
                />
              </IonItem>
            </IonList>
            <div>
              <IonButton expand="full" onClick={() => loginUser()}>
                Login
              </IonButton>
            </div>
            <div className="or">OR</div>
            {/* <div>
              <IonButton
                color="danger"
                expand="full"
                onClick={() => signInWithGoogle()}
              >
                <img
                  src="/assets/logo-google.svg"
                  className="button-icon"
                  alt="Google"
                />
                Login with Google
              </IonButton>
            </div> */}
            <div
              className="link"
              onClick={() => {
                router.push('/membership', 'forward', 'push');
                window.location.reload();
              }}
            >
              Need a membership?
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Login;
