import React, { useEffect, useState } from 'react';
import './Annotation.scss';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from '@ionic/react';
import axios from 'axios';
import { Buffer } from 'buffer';

const apiUrl = 'http://ec2-35-172-80-100.compute-1.amazonaws.com:5000';

function Annotation({ currentUser }) {
  const inputFileRef = React.useRef();
  //const { photos, takePhoto, resetGallery } = usePhotoGallery();
  const [avatar, setAvatar] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [annotatedImage, setAnnotatedImage] = useState(null);

  useEffect(() => {
    if (selectedFile) {
      setAvatar(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  const uploadPhoto = async () => {
    let formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('file_name', selectedFile.name);
    formData.append('user_id', currentUser.uid);

    try {
      const response = await axios.post(`${apiUrl}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      });

      const aImage = await axios.get(
        `${apiUrl}/annotate?img_path=${response.data.path}`,
        {
          responseType: 'arraybuffer',
        }
      );

      const base64 = btoa(
        new Uint8Array(aImage.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      setAnnotatedImage(base64);
    } catch (error) {
      if (error.response) {
        // get response with a status code not in range 2xx
        console.log(error.response.data);
      } else if (error.request) {
        // no response
        console.log(error.request);
      } else {
        // Something wrong in setting up the request
        console.log('Error', error.message);
      }
      console.log(error.config);
    }
  };

  return (
    <div id="annotation">
      <IonPage>
        <IonContent className="ion-padding-horizontal">
          <>
            {selectedFile ? (
              <div>
                <h1>Feedback</h1>
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <IonImg src={avatar} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonRow className="ion-justify-content-evenly">
                  <IonButton
                    onClick={() => {
                      setSelectedFile(null);
                      setAnnotatedImage(null);
                    }}
                  >
                    Replace
                  </IonButton>
                  <IonButton
                    onClick={() => {
                      uploadPhoto();
                    }}
                  >
                    Feedback
                  </IonButton>
                </IonRow>
                {annotatedImage && (
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonImg
                          className="annotated-image"
                          style={{ width: '100%', height: 'auto' }}
                          src={`data:;base64,${annotatedImage}`}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}
              </div>
            ) : (
              <div className="placeholder">
                <h1>
                  <em>f</em>v
                </h1>
                <h2>Feedback</h2>
                <input
                  ref={inputFileRef}
                  type="file"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  placeholder="Select Photo"
                  style={{ opacity: 0, display: 'none' }}
                />
                <IonButton onClick={() => inputFileRef?.current.click()}>
                  Take Photo
                </IonButton>
              </div>
            )}
          </>
        </IonContent>
      </IonPage>
    </div>
  );
}

export default Annotation;
