import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './Home.scss';

const About = () => {
  return (
    <IonPage id="home-page">
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>Welcome</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div>Put your content in here</div>
      </IonContent>
    </IonPage>
  );
};

export default About;
