import { useState, useEffect, createContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
  IonTabBar,
  IonTabs,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonToast,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  accessibility,
  person,
  checkmark,
  camera,
  barbell,
} from 'ionicons/icons';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Athletes from './pages/Athletes/Athletes';
import Schedules from './pages/Schedules/Schedules';
import Detail from './pages/Detail/Detail';
import Profile from './pages/Profile/Profile';
import Workout from './pages/Workout/Workout';
import Membership from './pages/Login/Membership';
import Home from './pages/Home/Home';
import Catalog from './pages/Catalog/Catalog';
import CatalogDetail from './pages/CatalogDetail/CatalogDetail';
import About from './pages/Home/Home';
import Annotation from './pages/Annotation/Annotation';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';

//apis
import { callTrainersList } from './helpers/api';

//firebase
import { fire } from './helpers/fire';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const auth = getAuth(fire);

setupIonicReact();

export const CurrentUserContext = createContext();
export const TrainerContext = createContext();

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState('trainer');
  const [currentUser, setCurrentUser] = useState(null);
  const [trainersList, setTrainersList] = useState(null);
  const [trainer, setTrainer] = useState(null);
  const [toast, setToast] = useState(null);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (currentUser) return;
      setCurrentUser(user);
      setIsLoggedIn(true);
    } else {
      setCurrentUser(null);
      setIsLoggedIn(false);
    }
    return;
  });

  const getData = async () => {
    if (userType === 'trainer') {
      callTrainersList(currentUser.uid, setTrainersList, setTrainer);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getData();
    }
  }, [isLoggedIn]);

  const handleToast = (toast) => {
    console.log('handleToast', toast);
    setToast(toast);
  };

  return (
    <IonApp>
      <IonToast
        isOpen={toast !== null}
        onDidDismiss={() => setToast(null)}
        message={toast?.message}
        duration={3000}
        color={toast?.color ? toast.color : 'success'}
        cssClass="toast"
        icon={checkmark}
      />
      <CurrentUserContext.Provider value={currentUser}>
        <TrainerContext.Provider value={trainersList}>
          {!currentUser && (
            <IonReactRouter>
              <IonRouterOutlet>
                <Route path="/">
                  <Redirect to="/login" />
                  <Login
                    setIsLoggedIn={setIsLoggedIn}
                    setCurrentUser={setCurrentUser}
                  />
                </Route>
                <Route path="/home">
                  <Home />
                </Route>
                <Route path="/catalog">
                  <Catalog />
                </Route>
                <Route path="/catalog-detail">
                  <CatalogDetail />
                </Route>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/workout">
                  <Workout />
                </Route>
                <Route path="/membership">
                  <Membership />
                </Route>
              </IonRouterOutlet>
            </IonReactRouter>
          )}
          {currentUser && isLoggedIn && (
            <IonReactRouter>
              <IonTabs>
                <IonRouterOutlet>
                  <Route path="/" exact={true}>
                    <Redirect to="/athletes" />
                  </Route>
                  <Route path="/logout" exact={true}>
                    <Logout
                      setCurrentUser={setCurrentUser}
                      setIsLoggedIn={setIsLoggedIn}
                      currentUser={currentUser}
                    />
                  </Route>
                  <Route path="/annotation">
                    <Annotation currentUser={currentUser} />
                  </Route>
                  <Route path="/catalog">
                    <Catalog />
                  </Route>
                  <Route path="/catalog-detail">
                    <CatalogDetail />
                  </Route>
                  <Route path="/about">
                    <About />
                  </Route>
                  <Route path="/profile" exact={true}>
                    <Profile currentUser={currentUser} />
                  </Route>
                  <Route path="/schedules" exact={true}>
                    <Schedules />
                  </Route>
                  <Route path="/athletes" exact={true}>
                    <Athletes />
                  </Route>
                  <Route path="/athlete/:athleteId">
                    <Detail trainer={trainer} handleToast={handleToast} />
                  </Route>
                  <Route path="/login" exact={true}>
                    <Redirect to="/athletes" />
                  </Route>
                  <Route path="/workout">
                    <Workout />
                  </Route>
                </IonRouterOutlet>
                <IonTabBar slot="bottom">
                  <IonTabButton tab="Athletes" href={`/athletes`}>
                    <IonIcon icon={accessibility} />
                    <IonLabel>Athletes</IonLabel>
                  </IonTabButton>

                  <IonTabButton tab="Annotation" href="/annotation">
                    <IonIcon icon={camera} />
                    <IonLabel>Feedback</IonLabel>
                  </IonTabButton>

                  {/* <IonTabButton tab='Workouts' href='/workouts'>
                      <IonIcon icon={barbell} />
                      <IonLabel>Workouts</IonLabel>
                    </IonTabButton>

                    <IonTabButton tab='Schedules' href='/schedules'>
                      <IonIcon icon={calendar} />
                      <IonLabel>Schedules</IonLabel>
                    </IonTabButton> */}

                  <IonTabButton tab="Profile" href="/profile">
                    <IonIcon icon={person} />
                    <IonLabel>Profile</IonLabel>
                  </IonTabButton>
                </IonTabBar>
              </IonTabs>
            </IonReactRouter>
          )}
        </TrainerContext.Provider>
      </CurrentUserContext.Provider>
    </IonApp>
  );
};

export default App;
