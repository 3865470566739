//import axios from 'axios';
import { getDatabase, ref, onValue, update, set, put } from 'firebase/database';
import { fire } from './fire';

const db = getDatabase(fire);

export const addTrainer = async (id, firstName, lastName, email, cellphone) => {
  const updates = {};
  const trainerObj = {
    firstName,
    lastName,
    name: `${firstName} ${lastName}`,
    email,
    cellphone,
  };
  updates['/trainers/' + id] = trainerObj;

  const res = await update(ref(db), updates);
  return res;
};

export const callTrainersList = async (id, setFbTrainersData, setTrainer) => {
  const dbRef = await ref(db, 'trainers/' + id);
  onValue(dbRef, (snapshot) => {
    const data = snapshot.val();
    const trainersArray = Object.values(data?.athletes);
    const sorted = trainersArray.sort((a, b) =>
      `${a.firstName}`.localeCompare(`${b.firstName}`)
    );
    setFbTrainersData(sorted);
    setTrainer(data.name);
    return sorted;
  });
};

export const getUser = async (setFbUser, athleteId, athleteData) => {
  const dbRef = await ref(db, 'users/');
  onValue(dbRef, (snapshot) => {
    const data = snapshot.val();
    const athlete = { ...athleteData, ...data[athleteId] };
    setFbUser(athlete);
    return athlete;
  });
};

export const updateTrainersFile = async (editedUser, currentUserId) => {
  // Write the new post's data simultaneously in the posts list and the user's post list.
  const updates = {};
  updates['/trainers/' + currentUserId + '/athletes/' + editedUser.id] =
    editedUser;

  const updated = update(ref(db), updates);
  return updated;
};

export const addNewAthleteToTrainers = async (user, currentUserId) => {
  const updates = {};
  updates['/trainers/' + currentUserId + '/athletes/' + user.id] = user;

  const res = await update(ref(db), updates);
  return res;
};

export const addWorkoutToAthleteInTrainers = async (
  newWorkout,
  currentUserId,
  athleteId
) => {
  const updates = {};
  updates[
    '/trainers/' +
      currentUserId +
      '/athletes/' +
      athleteId +
      '/workouts/' +
      newWorkout.id
  ] = newWorkout;

  const res = await update(ref(db), updates);
  return res;
};

export const deleteWorkoutFromAthleteInTrainersList = async (
  currentUserId,
  athleteId,
  workoutId
) => {
  const updates = {};
  updates[
    '/trainers/' +
      currentUserId +
      '/athletes/' +
      athleteId +
      '/workouts/' +
      workoutId
  ] = null;

  const res = await update(ref(db), updates);
  return res;
};

export const callFBItem = async (table, id, setFbTrainersData) => {
  let data;
  const dbRef = await ref(db, 'trainers/' + id);
  onValue(dbRef, (snapshot) => {
    data = snapshot.val();
    setFbTrainersData(data);
    return data;
  });
  return data;
};

export const callFBList = async (table, setFbUsers) => {
  let data;
  const dbRef = await ref(db, 'users/');
  onValue(dbRef, (snapshot) => {
    data = snapshot.val();
    setFbUsers(data);
  });
  return data;
};

export const addSharedWorkout = async (id, btoaedWorkout) => {
  const updates = {};
  updates['/sharedWorkouts/' + id] = btoaedWorkout;

  const res = await update(ref(db), updates);
  return res;
};

export const deleteAthlete = async (currentUserId, athleteId) => {
  const updates = {};
  updates['/trainers/' + currentUserId + '/athletes/' + athleteId] = null;

  const res = await update(ref(db), updates);
  return res;
};

export const getSharedWorkout = async (
  id,
  setWorkouts,
  setDate,
  setNote,
  setTrainer,
  setName
) => {
  let data;
  const dbRef = await ref(db, 'sharedWorkouts/' + id);
  onValue(dbRef, (snapshot) => {
    data = snapshot.val();
    const p = JSON.parse(atob(data));
    setDate(p.date);
    setName(p.name);
    setNote(p.note);
    setTrainer(p.trainer);
    setWorkouts(p.workouts);
  });
};
