import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonToolbar,
  IonList,
  IonInput,
  IonButton,
  IonTextarea,
  IonTitle,
} from '@ionic/react';

export const AddAthlete = ({ setIsOpen, handleAddAthlete }) => {
  const [birthDate, setBirthDate] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [location, setLocation] = useState('');
  const [email, setEmail] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [note, setNote] = useState('');

  const handleSave = () => {
    const id = uuidv4();
    handleAddAthlete({
      note,
      id,
      avatar: '',
      email,
      cellPhone,
      firstName,
      lastName,
      location,
      birthDate,
      workouts: '',
    });

    setIsOpen(false);
  };

  return (
    <>
      <IonHeader mode='ios'>
        <IonToolbar>
          <IonTitle></IonTitle>
          <IonButtons slot='start'>
            <IonButton color='medium' onClick={() => setIsOpen(false)}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton
              color='primary'
              disabled={firstName.length === 0 || cellPhone.length === 0}
              onClick={() => handleSave()}
            >
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <h1>Add Athlete</h1>
        <div className='add-workout'>
          <IonList className='workouts'>
            {/* <IonItem>
              <IonInput
                className='wider photo'
                value={email}
                placeholder='Upload Photo'
                type='file'
                onIonChange={(e) => setEmail(e.target.value)}
              />
            </IonItem> */}
            <IonItem>
              <IonLabel>Email</IonLabel>
              <IonInput
                className='wider'
                value={email}
                placeholder=''
                type='email'
                onIonChange={(e) => setEmail(e.target.value)}
              />
            </IonItem>
            <IonItem>
              <IonLabel>Cellphone*</IonLabel>
              <IonInput
                className='wider'
                type='tel'
                pattern='tel'
                value={cellPhone}
                placeholder=''
                onIonChange={(e) => setCellPhone(e.target.value)}
              />
            </IonItem>
            <IonItem>
              <IonLabel>First Name*</IonLabel>
              <IonInput
                autocapitalize={true}
                className='wider'
                value={firstName}
                placeholder=''
                onIonChange={(e) => setFirstName(e.target.value)}
              />
            </IonItem>

            <IonItem>
              <IonLabel>Last Name</IonLabel>
              <IonInput
                className='wider'
                autocapitalize={true}
                value={lastName}
                placeholder=''
                onIonChange={(e) => setLastName(e.target.value)}
              />
            </IonItem>

            <IonItem>
              <IonLabel>Location</IonLabel>
              <IonInput
                className='wider'
                autocapitalize={true}
                value={location}
                placeholder=''
                onIonChange={(e) => setLocation(e.target.value)}
              />
            </IonItem>
          </IonList>

          <IonList>
            <IonItem>
              <IonLabel>Birthdate</IonLabel>
              <IonInput
                placeholder='mm/dd/yyyy'
                onIonChange={(e) => setBirthDate(e.target.value)}
              ></IonInput>
            </IonItem>
          </IonList>
          <h1>Notes</h1>
          <IonList className='note'>
            <IonItem>
              <IonTextarea
                autocapitalize={true}
                value={note}
                rows='5'
                placeholder='Add notes...'
                onIonChange={(e) => setNote(e.target.value)}
              ></IonTextarea>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </>
  );
};

export default AddAthlete;
