import { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonList,
  IonToast,
  IonItem,
  IonLabel,
  IonCheckbox,
} from '@ionic/react';

import { getFormattedDate, isToday } from '../../helpers/helpers';

import { getSharedWorkout } from '../../helpers/api';

import './Workout.scss';

function Workout() {
  const [showToast, setShowToast] = useState(null);
  const [trainer, setTrainer] = useState(null);
  const [workouts, setWorkouts] = useState(null);
  const [note, setNote] = useState(null);
  const [date, setDate] = useState(null);
  const [name, setName] = useState(null);

  const getData = async (id) => {
    //get sharedWorkout from FB
    getSharedWorkout(id, setWorkouts, setDate, setNote, setTrainer, setName);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const workoutId = searchParams.get('a');

    getData(workoutId);
  }, []);

  const showLineCheckboxes = (type) => {
    if (type === 'AMRAP' || type === 'Circuit') return false;
    return true;
  };

  const showTypeCheckboxes = (type) => {
    if (type === 'AMRAP' || type === 'Circuit') return true;
    return false;
  };

  return (
    <IonPage id="view-athlete-page" className="workout">
      <IonToast
        isOpen={showToast !== null}
        onDidDismiss={() => setShowToast(null)}
        message={showToast?.message}
        duration={3000}
        color="success"
        cssClass="toast"
      />
      <IonContent fullscreen className="ion-padding">
        <h4>
          <span className="faded">{getFormattedDate(date)} </span>
          {isToday(date) ? <ion-text color="success">Today </ion-text> : ''}
        </h4>
        <h1>{name}'s Workout</h1>

        <div>
          {workouts?.map((w) => (
            <>
              <h3>
                {showTypeCheckboxes(w.type) && <IonCheckbox slot="start" />}
                <span>
                  {w.type}{' '}
                  {/* {d['total time'] &&
                    `for ${formatSecondsToMins(+d['total time'])}`} */}
                  {w['total time'] && `for ${+w['total time']} mins`}
                </span>
              </h3>

              {w.instructions && <p>{w.instructions}</p>}

              {/* {Object.entries(w)
                .filter(([key, value]) => key !== 'data' && key !== 'type')
                .map(([key, value]) => (
                  <div className='faded'>
                    {camelToSpace(key)} :{' '}
                    {key.toLowerCase().includes('time') ? value : 'value'}
                  </div>
                ))} */}
              <IonList lines="full">
                {w.data.map((i) => (
                  <IonItem>
                    {showLineCheckboxes(w.type) && <IonCheckbox slot="start" />}
                    <IonLabel text-wrap>
                      <div key={i.exercise + i.index}>
                        <b>{i.exercise}</b> {i.weight && `${i.weight} lbs`}{' '}
                        {i.reps && `for ${i.reps} reps`}
                        {i.time && `for ${i.time} mins`}
                        {i.repeat && ` x ${i.repeat} sets`}
                        {i.calories && `for ${i.calories} calories`}
                        {i.targetTime && `for ${i.targetTime} mins`}
                      </div>
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            </>
          ))}
          {note && <p className="faded">Note: {note}</p>}
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Workout;
