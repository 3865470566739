import React, { useState, useEffect } from 'react';
import { workoutTypes, cardioWorkouts } from '../../helpers/helpers';
import cloneDeep from 'lodash/cloneDeep';

import {
  IonItem,
  IonLabel,
  IonList,
  IonInput,
  IonButton,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonIcon,
} from '@ionic/react';

import { trash } from 'ionicons/icons';

export const AddWorkoutSection = ({
  setId,
  handleUpdateSetData,
  workoutEditItem,
}) => {
  const [workoutItem, setWorkoutItem] = useState(null);
  const [workoutArray, setWorkoutArray] = useState([]);
  const [extraFields, setExtraFields] = useState([]);
  const [type, setType] = useState(cloneDeep(workoutTypes[0]));

  //New Code
  useEffect(() => {
    let extraFieldsWithData;
    let fields;

    if (workoutEditItem) {
      setType(
        workoutEditItem?.type
          ? workoutTypes?.find((w) => w?.name === workoutEditItem?.type)
          : workoutTypes[0]
      );
      const editType = workoutTypes.find(
        (w) => w.name === workoutEditItem.type
      );

      //Fill type with editData
      const extraFields = editType?.extraFields;
      extraFieldsWithData = extraFields?.map((e) => {
        if (workoutEditItem[e.name] !== null) {
          e.value = workoutEditItem[e.name];
          return e;
        }
      });

      fields = workoutEditItem?.data.map((d) => {
        const entries = Object.entries(d);
        const fieldArray = entries.map((e) => {
          const foundWorkout = editType?.fields.find((w) => {
            return w.name === e[0];
          });
          foundWorkout.value = e[1];
          return { ...foundWorkout };
        });
        return fieldArray;
      });

      const completeFields = fields.map((f) =>
        [...f, ...type.fields].filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        )
      );

      setWorkoutItem(completeFields);
      setWorkoutArray(completeFields);
      setExtraFields(extraFieldsWithData || type.extraFields);
    } else {
      setWorkoutItem(cloneDeep(type.fields));
      setWorkoutArray([cloneDeep(type.fields)]);
      setExtraFields(cloneDeep(type.extraFields));
    }
  }, [type, workoutEditItem]);

  const handleChange = (e, event, index) => {
    const formattedWorkoutArray = workoutArray.map((w) => {
      const wArrays = w.map((ww) => ({
        [ww.name]: ww.value,
      }));
      return Object.assign(...wArrays);
    });

    const formattedSection = {
      type: type.name,
      data: formattedWorkoutArray,
    };

    const extraFieldsFormatted = extraFields.map((e) => ({
      [e.name]: e.value,
    }));

    const combined = {
      ...formattedSection,
      ...Object.assign(...extraFieldsFormatted),
    };

    handleUpdateSetData(setId, combined);
  };

  const removeExercise = (indexx) => {
    const newExercises = workoutArray.filter((w, index) => index !== indexx);
    setWorkoutArray(newExercises);
  };

  const addNewExercise = () => {
    const newFields = type.fields.map((t) => ({ ...t, value: '' }));
    setWorkoutArray([...workoutArray, newFields]);
  };

  const handleAddTextAreaData = (id, value) => {
    const waTextArea = workoutArray.map((w) =>
      w.map((ww) => {
        if (ww.id === id) {
          ww.value = value;
        }
        return ww;
      })
    );
  };

  return (
    <>
      <IonList lines="none">
        <div className="workouts-item">
          <IonItem>
            <IonLabel>Type</IonLabel>
            <IonSelect
              className="wider"
              interface="popover"
              placeholder="Select Type"
              onIonChange={(e) => setType(e.target.value)}
              value={type}
            >
              {workoutTypes.map((t) => (
                <IonSelectOption key={t.id} value={t}>
                  {t.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          {extraFields?.length > 0 &&
            extraFields?.map((e, index) => {
              if (e?.name?.toLowerCase() === 'Instructions') {
                return (
                  <IonList className="note" key={e.name + index}>
                    <IonItem>
                      <IonTextarea
                        autocapitalize="true"
                        value={e.value}
                        rows="5"
                        placeholder="Instructions..."
                        onIonBlur={(event) => handleChange(e, event, index)}
                        onIonChange={(event) => {
                          e.value = event.target.value;
                          handleChange(e, index);
                        }}
                      ></IonTextarea>
                    </IonItem>
                  </IonList>
                );
              } else {
                return (
                  <IonItem key={e?.name + index}>
                    <IonLabel>
                      {e?.name} <small className="faded">{e?.helper}</small>
                    </IonLabel>
                    <IonInput
                      debounce={500}
                      autocapitalize={e?.type === '' ? true : false}
                      type={e?.type}
                      value={e?.value}
                      placeholder={e?.placeholder ? e.placeholder : ''}
                      onIonChange={(event) => {
                        e.value = event.target.value;
                        handleChange(e, event, index);
                      }}
                    />
                  </IonItem>
                );
              }
            })}
        </div>
      </IonList>

      <IonList className="workouts">
        {workoutArray.map((w, index) => (
          <div key={`w${index}`} className="workouts-item">
            {workoutArray.length > 1 && (
              <IonIcon
                className="delete-icon"
                slot="icon-only"
                color="danger"
                icon={trash}
                onClick={() => removeExercise(index)}
              />
            )}

            {w.map((ww, indexx) => (
              <>
                {type.name === 'Cardio' && (
                  <>
                    {indexx === 0 && (
                      <IonItem>
                        <IonLabel>Name</IonLabel>
                        <IonSelect
                          className="wider"
                          interface="popover"
                          placeholder="Select Cardio Exercise"
                          onIonChange={(e) => {
                            ww.value = e.target.value;
                            handleChange(ww, e, index, ww.value);
                            handleAddTextAreaData(
                              `cardio-${indexx + 1}`,
                              e.target.value.description
                            );
                          }}
                          value={ww.value}
                        >
                          {cardioWorkouts.map((t) => (
                            <IonSelectOption key={t.id} value={t}>
                              {t.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    )}
                    {indexx > 0 && (
                      <IonItem>
                        {ww.type === 'textarea' && (
                          <>
                            <IonTextarea
                              autocapitalize="true"
                              rows="5"
                              value={ww.value}
                              placeholder={ww.placeholder ? ww.placeholder : ''}
                              onIonChange={(e) => {
                                ww.value = e.target.value;
                                handleChange(ww, e, index);
                              }}
                            />
                          </>
                        )}
                        {ww.type !== 'textarea' && (
                          <>
                            <IonLabel>
                              {ww.name === 'exercise' ? 'name' : ww.name}
                              <small className="faded"> {ww.helper}</small>
                            </IonLabel>
                            <IonInput
                              debounce={500}
                              autocapitalize={ww.type === '' ? true : false}
                              value={ww.value}
                              placeholder={ww.placeholder ? ww.placeholder : ''}
                              onIonChange={(e) => {
                                ww.value = e.target.value;
                                handleChange(ww, e, index);
                              }}
                            />
                          </>
                        )}
                      </IonItem>
                    )}
                  </>
                )}

                {type.name !== 'Cardio' && (
                  <>
                    <IonItem key={ww.id}>
                      {ww.type === 'textarea' && (
                        <IonTextarea
                          autocapitalize="true"
                          rows="5"
                          value={ww.value}
                          placeholder={ww.placeholder ? ww.placeholder : ''}
                          onIonChange={(e) => {
                            ww.value = e.target.value;
                            handleChange(ww, e, index);
                          }}
                        ></IonTextarea>
                      )}

                      {ww.type !== 'textarea' && (
                        <>
                          <IonLabel>
                            {ww.name === 'exercise' ? 'name' : ww.name}
                            <small className="faded"> {ww.helper}</small>
                          </IonLabel>
                          <IonInput
                            debounce={500}
                            autocapitalize={ww.type === '' ? true : false}
                            value={ww.value}
                            placeholder={ww.placeholder ? ww.placeholder : ''}
                            onIonChange={(e) => {
                              ww.value = e.target.value;
                              handleChange(ww, e, index);
                            }}
                          />
                        </>
                      )}
                    </IonItem>
                  </>
                )}
              </>
            ))}
          </div>
        ))}

        {workoutArray.length > 0 && (
          <IonButton
            className="add-new-exercise"
            expand="block"
            fill="clear"
            onClick={() => addNewExercise()}
          >
            Add New Exercise for Set
          </IonButton>
        )}
      </IonList>
    </>
  );
};

export default AddWorkoutSection;
