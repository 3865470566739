export const getFormattedDate = (date, noDay) => {
  const d = new Date(date);
  var year = d.getFullYear();

  var month = (1 + d.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = d.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  if (noDay) {
    return `${month}/${day}/${year}`;
  }
  return `${d.toLocaleString('default', {
    weekday: 'short',
  })} ${month}/${day}/${year}`;
};

export const formatSecondsToMins = (seconds) => {
  var numyears = Math.floor(seconds / 31536000);
  var numdays = Math.floor((seconds % 31536000) / 86400);
  var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
  if (numhours > 0) {
    return numhours + ' hr ' + numminutes + ' min ' + numseconds + ' sec';
  } else if (numminutes > 0) {
    if (numseconds > 0) {
      return numminutes + ' min ' + numseconds + ' sec';
    } else {
      return numminutes + ' min';
    }
  } else return numseconds + '  sec';
};

export const isToday = (date) => {
  const someDate = new Date(date);
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};

export const camelToSpace = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const handleLink = (type, link, msg) => {
  //for ios, need to handle android
  if (type === 'tel' || type === 'sms') {
    const countryCode = link.slice(0, 2);
    if (countryCode !== '+1') {
      const phoneNumberWithCountryCode = `+1${link}`;
      if (msg) {
        window.open(`${type}:/${phoneNumberWithCountryCode}&body=${msg}`);
      } else {
        window.open(`${type}:/${phoneNumberWithCountryCode}`);
      }
    } else {
      if (msg) {
        window.open(`${type}:/${link}&body=${msg}`);
      } else {
        window.open(`${type}:/${link}`);
      }
    }
  } else {
    if (msg) {
      window.open(`${type}:/${link}&body=${msg}`);
    } else {
      window.open(`${type}:/${link}`);
    }
  }
};

export const workoutTypes = [
  {
    id: 0,
    name: 'Strength',
    description: 'Lift heavy things.',
    extraFields: [
      {
        id: 'STRENGH-Extra-0',
        name: 'total time',
        helper: '(minutes)',
        fieldType: 'numeric',
        value: '',
      },
    ],
    fields: [
      {
        id: 'STRENGH-0',
        name: 'exercise',
        type: '',
        placeholder: '',
        value: '',
      },
      {
        id: 'STRENGH-1',
        name: 'weight',
        helper: '(lbs)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'STRENGH-2',
        name: 'reps',
        type: 'numeric',
        value: '',
      },
      {
        id: 'STRENGH-3',
        name: 'time',
        helper: '(minutes)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'STRENGH-4',
        name: 'repeat',
        type: 'numeric',
        value: '',
      },
    ],
  },
  {
    id: 1,
    name: 'Superset',
    description:
      'Alternating sets of two different exercises with no rest in between.',
    extraFields: [
      {
        id: 'Superset-Extra-0',
        name: 'total time',
        helper: '(minutes)',
        fieldType: 'numeric',
        value: '',
      },
    ],
    fields: [
      {
        id: 'Superset-0',
        name: 'exercise',
        type: '',
        placeholder: '',
        value: '',
      },
      {
        id: 'Superset-1',
        name: 'weight',
        helper: '(lbs)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Superset-2',
        name: 'reps',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Superset-3',
        name: 'time',
        helper: '(minutes)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Superset-4',
        name: 'repeat',
        type: 'numeric',
        value: '',
      },
    ],
  },
  {
    id: 2,
    name: 'Compound',
    description: 'Work multiple muscle groups at the same time.',
    extraFields: [
      {
        id: 'Compound-Extra-0',
        name: 'total time',
        helper: '(minutes)',
        fieldType: 'numeric',
        value: '',
      },
    ],
    fields: [
      {
        id: 'Compound-0',
        name: 'exercise',
        type: '',
        placeholder: '',
        value: '',
      },
      {
        id: 'Compound-1',
        name: 'weight',
        helper: '(lbs)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Compound-2',
        name: 'reps',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Compound-3',
        name: 'time',
        helper: '(minutes)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Compound-4',
        name: 'repeat',
        type: 'numeric',
        value: '',
      },
    ],
  },
  {
    id: 3,
    name: 'AMRAP',
    description: 'As many reps or rounds.',
    extraFields: [
      {
        id: 'AMRAP-Extra-0',
        name: 'total time',
        helper: '(minutes)',
        fieldType: 'numeric',
        value: '',
      },
    ],
    fields: [
      {
        id: 'AMRAP-0',
        name: 'exercise',
        type: '',
        placeholder: '',
        value: '',
      },
      {
        id: 'AMRAP-1',
        name: 'weight',
        helper: '(lbs)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'AMRAP-2',
        name: 'reps',
        type: 'numeric',
        value: '',
      },
      {
        id: 'AMRAP-3',
        name: 'time',
        helper: '(minutes)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'AMRAP-4',
        name: 'repeat',
        type: 'numeric',
        value: '',
      },
    ],
  },
  {
    id: 4,
    name: 'Circuit',
    description:
      'A combination of exercises performed with short rest periods between them for either a set number of repetitions or a prescribed amount of time.',
    extraFields: [
      {
        id: 'Circuit-Extra-0',
        name: 'total time',
        helper: '(minutes)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Circuit-Extra-1',
        name: 'rounds',
        type: 'numeric',
        value: '',
      },
    ],
    fields: [
      {
        id: 'Circuit-0',
        name: 'exercise',
        type: '',
        placeholder: '',
        value: '',
      },
      {
        id: 'Circuit-1',
        name: 'weight',
        helper: '(lbs)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Circuit-2',
        name: 'reps',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Circuit-3',
        name: 'time',
        helper: '(minutes)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Circuit-4',
        name: 'repeat',
        type: 'numeric',
        value: '',
      },
    ],
  },
  {
    id: 5,
    name: 'Cardio',
    description: 'Some description.',
    extraFields: [
      {
        id: 'Cardio-Extra-0',
        name: 'total time',
        helper: '(minutes)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Cardio-Extra-1',
        name: 'rounds',
        type: 'numeric',
        value: '',
      },
    ],
    fields: [
      {
        id: 'cardio-0',
        name: 'exercise',
        type: '',
        placeholder: '',
        value: '',
      },
      {
        id: 'cardio-1',
        name: 'exercise',
        type: 'textarea',
        placeholder: 'Write cardio intructions...',
        value: '',
      },
      {
        id: 'cardio-2',
        name: 'time',
        helper: '(minutes)',
        type: 'numeric',
        value: '',
      },
    ],
  },
  {
    id: 6,
    name: 'EMOM',
    description: 'Every minute on the minute.',
    extraFields: [
      {
        id: 'EMOM-Extra-0',
        name: 'total time',
        helper: '(minutes)',
        type: 'numeric',
        value: '',
      },
    ],
    fields: [
      {
        id: 'EMOM-0',
        name: 'exercise',
        type: '',
        placeholder: '',
        value: '',
      },
      {
        id: 'EMOM-1',
        name: 'weight',
        helper: '(lbs)',
        type: 'numeric',
        placeholder: '',
        value: '',
      },
    ],
  },
  {
    id: 7,
    name: 'Ladder',
    description: '10-1 means 10 reps minus one until you reach 1.',
    extraFields: [
      {
        id: 'Ladder-Extra-0',
        name: 'instructions',
        type: '',
        placeholder: '',
        value: '',
      },
    ],
    fields: [
      {
        id: 'Ladder-0',
        name: 'exercise',
        type: '',
        placeholder: '',
        value: '',
      },
      {
        id: 'Ladder-1',
        name: 'weight',
        helper: '(lbs)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Ladder-2',
        name: 'reps',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Ladder-3',
        name: 'time',
        helper: '(minutes)',
        type: 'numeric',
        value: '',
      },
      {
        id: 'Ladder-4',
        name: 'repeat',
        type: 'numeric',
        value: '',
      },
    ],
  },
  {
    id: 8,
    name: 'Pyramid',
    description: 'Some description.',
    extraFields: [
      {
        id: 'Pyramid-Extra-0',
        name: 'instructions',
        type: '',
        value: '',
      },
    ],
    fields: [
      {
        id: 'Pyramid-0',
        name: 'exercise',
        type: '',
        placeholder: '',
        value: '',
      },
      {
        id: 'Pyramid-1',
        name: 'weight',
        helper: '(lbs)',
        type: 'numeric',
        value: '',
      },
    ],
  },
];

export const cardioWorkouts = [
  {
    id: 0,
    name: '30 on 30 off',
    description: 'Max effort for 30 seconds / rest 30 seconds',
  },
  {
    id: 1,
    name: 'Tabata',
    description: 'Max effort for 20 seconds / rest 10 seconds for 8 rounds',
  },
  {
    id: 2,
    name: 'Bike (Calories)',
    description:
      'Bike for 50, 40, 30, 20, 10 calories. Rest for how long it takes to get to each. If it takes 3 minutes to get to 50 cals, rest for 3 minutes. 2 mins to get to 40, rest for 2 mins, etc.',
  },
  {
    id: 3,
    name: 'Row (Calories)',
    description:
      'Row for 50, 40, 30, 20, 10 calories. Rest for how long it takes to get to each. If it takes 3 minutes to get to 50 cals, rest for 3 minutes. 2 mins to get to 40, rest for 2 mins, etc.',
  },
  {
    id: 4,
    name: 'Ski (Calories)',
    description:
      'Ski for 50, 40, 30, 20, 10 calories. Rest for how long it takes to get to each. If it takes 3 minutes to get to 50 cals, rest for 3 minutes. 2 mins to get to 40, rest for 2 mins, etc.',
  },
  {
    id: 5,
    name: 'Row (Distance)',
    description:
      'Row for 500, 400, 300, 200, 100 meters. Rest for time it takes to get to each. If it takes 2 minutes to reach 500 meters, rest for two minutes. 1:30 minutes to get to 400, rest for 1:30, etc.',
  },
  {
    id: 6,
    name: 'Triathlon',
    description: 'Row, ski, bike, for 21, 15, 9, calories each.',
  },
];
