import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './CatalogDetail.scss';

const CatalogDetail = () => {
  return (
    <IonPage id="catalog-detail">
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>Detail</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div>Put content in here</div>
      </IonContent>
    </IonPage>
  );
};

export default CatalogDetail;
