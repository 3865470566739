import { IonItem, IonLabel, IonAvatar } from '@ionic/react';
import './ListItem.scss';

const ListItem = ({ item }) => {
  return (
    <div className="list-item">
      <IonItem routerLink={`/athlete/${item.id}`} detail={false}>
        <IonAvatar slot="start">
          {item?.avatar?.length === 0 ? (
            <div className="avatar-initials">
              {item.firstName?.slice(0, 1)} {item.lastName?.slice(0, 1)}
            </div>
          ) : (
            <img alt={item.firstName} src={item.avatar} />
          )}
        </IonAvatar>
        <IonLabel className="ion-text-wrap">
          <h2>
            {item.firstName} {item.lastName}
          </h2>
          <h3>{item.location}</h3>
        </IonLabel>
      </IonItem>
    </div>
  );
};

export default ListItem;
