import { useState, useContext, useEffect } from 'react';
import { TrainerContext, CurrentUserContext } from '../../App';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonList,
  IonButton,
  IonIcon,
  IonModal,
  IonToast,
  IonSearchbar,
} from '@ionic/react';
import ListItemWorkout from '../../components/ListItemWorkout/ListItemWorkout';
import AddWorkout from './AddWorkout';
import SearchWorkouts from './SearchWorkouts';
import EditAthlete from './EditAthlete';
import { add, call, mail, send, checkmark } from 'ionicons/icons';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import {
  getUser,
  updateTrainersFile,
  addWorkoutToAthleteInTrainers,
  deleteWorkoutFromAthleteInTrainersList,
} from '../../helpers/api';
import { handleLink } from '../../helpers/helpers';
import './Detail.scss';

function Detail({ userId, trainer, handleToast }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [addWorkoutIsOpen, setAddWorkoutIsOpen] = useState(false);
  const [showToast, setShowToast] = useState(null);
  const [fbUser, setFbUser] = useState(null);
  const [workoutEdit, setWorkoutEdit] = useState(null);
  const params = useParams();

  const data = useContext(TrainerContext);
  const currentUser = useContext(CurrentUserContext);

  const getUserData = async () => {
    const athleteDataFromTrainer = data.find((d) => d.id === params.athleteId);
    const users = await getUser(
      setFbUser,
      params.athleteId,
      athleteDataFromTrainer
    );
  };

  useEffect(() => {
    getUserData();
  }, [params, data]);

  const isHidden = (w) => {
    if (searchQuery === '') return;
    const stringified = JSON.stringify(w.data)?.toLowerCase();
    const contains = stringified.includes(searchQuery?.toLowerCase());
    if (contains) return false;
    return true;
  };

  const handleEditWorkout = (workout) => {
    setAddWorkoutIsOpen(true);
    setWorkoutEdit(workout);
  };

  const handleAddWorkout = (
    selectedDate,
    note,
    sections,
    isEdit,
    athleteId
  ) => {
    const id = isEdit?.id || uuidv4();
    const addedWorkout = {
      date: new Date(selectedDate),
      id,
      data: sections,
      note,
    };

    addWorkoutToAthleteInTrainers(
      addedWorkout,
      currentUser.uid,
      athleteId || fbUser.id
    );

    if (isEdit) {
      setShowToast({ message: 'Successfully updated workout.' });
    } else {
      setShowToast({ message: 'Successfully added workout.' });
    }
  };

  const handleEditAthlete = async (editedUser) => {
    //update firebase trainers file
    const trainersFile = await updateTrainersFile(editedUser, currentUser.uid);
    //update firebase users file
    const user = setFbUser(editedUser);
    setShowToast({ message: 'Successfully edited info.' });
  };

  const handleDeleteWorkoutFromAthletesList = (item) => {
    deleteWorkoutFromAthleteInTrainersList(currentUser.uid, fbUser.id, item.id);
    setShowToast({ message: 'Successfully deleted workout.' });
  };

  return (
    <IonPage id="view-athlete-page">
      <IonToast
        isOpen={showToast !== null}
        onDidDismiss={() => setShowToast(null)}
        message={showToast?.message}
        duration={3000}
        color="success"
        cssClass="toast"
        icon={checkmark}
      />
      <IonHeader mode="ios">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Athletes" defaultHref="/home"></IonBackButton>
          </IonButtons>
          <IonButtons slot="primary" onClick={() => setEditIsOpen(true)}>
            <IonButton>Edit</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonModal isOpen={editIsOpen}>
          <EditAthlete
            setEditIsOpen={setEditIsOpen}
            user={fbUser}
            currentUserId={currentUser.uid}
            handleEditAthlete={handleEditAthlete}
            handleToast={handleToast}
          />
        </IonModal>
        <IonModal isOpen={addWorkoutIsOpen}>
          <AddWorkout
            setAddWorkoutIsOpen={setAddWorkoutIsOpen}
            user={fbUser}
            handleAddWorkout={handleAddWorkout}
            workoutEdit={workoutEdit}
          />
        </IonModal>
        <IonModal isOpen={searchIsOpen}>
          <SearchWorkouts
            setSearchIsOpen={setSearchIsOpen}
            user={fbUser}
            athleteId={params.athleteId}
            trainer={trainer}
          />
        </IonModal>
        {fbUser ? (
          <>
            <div className="profile-section">
              {fbUser?.avatar?.length > 0 && (
                <img
                  alt={`${fbUser?.firstName} ${fbUser?.firstName}`}
                  src={fbUser?.avatar}
                />
              )}
              {fbUser?.avatar?.length === 0 && (
                <div className="avatar-no-image">
                  {fbUser?.firstName?.slice(0, 1)}
                  {fbUser?.lastName?.slice(0, 1)}
                </div>
              )}
              <h4>
                {fbUser.firstName} {fbUser.lastName}
              </h4>
            </div>
            <div className="actions">
              <IonButtons>
                <IonButton
                  color="dark"
                  onClick={() => handleLink('tel', fbUser.cellPhone)}
                >
                  <IonIcon slot="icon-only" icon={call} />
                </IonButton>
                <IonButton
                  color="dark"
                  onClick={() => handleLink('sms', fbUser.cellPhone)}
                >
                  <IonIcon slot="icon-only" icon={send} />
                </IonButton>
                <IonButton
                  color="dark"
                  onClick={() => handleLink('mailto', fbUser.email)}
                >
                  <IonIcon slot="icon-only" icon={mail} />
                </IonButton>
              </IonButtons>
            </div>

            <div className="ion-padding">
              <h1>
                <IonSearchbar
                  className="search-autofocus"
                  value={searchQuery}
                  onIonChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Workouts..."
                  autoFocus={true}
                  debounce={500}
                  setFocus={true}
                />

                <IonButtons slot="primary">
                  {/* <IonButton onClick={() => setSearchIsOpen(true)}>
                    <IonIcon
                      slot="icon-only"
                      icon={searchOutline}
                      className="search-icon"
                    />
                  </IonButton> */}
                  <IonButton
                    onClick={() => {
                      setWorkoutEdit(null);
                      setAddWorkoutIsOpen(true);
                    }}
                  >
                    <IonIcon slot="icon-only" icon={add} />
                  </IonButton>
                </IonButtons>
              </h1>
              <IonList lines="full">
                {fbUser.workouts &&
                  Object.values(fbUser.workouts)
                    ?.sort(function (a, b) {
                      return new Date(b.date) - new Date(a.date);
                    })
                    .map((w) => (
                      <div key={w.date} className={isHidden(w) ? 'hidden' : ''}>
                        <ListItemWorkout
                          key={w.date}
                          type={w.type}
                          date={w.date}
                          item={w.data}
                          userId={userId}
                          athleteId={params.athleteId}
                          note={w.note}
                          cellPhone={fbUser.cellPhone}
                          email={fbUser.email}
                          firstName={fbUser.firstName}
                          lastName={fbUser.lastName}
                          trainer={trainer}
                          handleDeleteWorkoutFromAthletesList={
                            handleDeleteWorkoutFromAthletesList
                          }
                          handleEditWorkout={handleEditWorkout}
                          workout={w}
                          data={w.data}
                        />
                      </div>
                    ))}
              </IonList>
            </div>
          </>
        ) : (
          <div>Athlete not found</div>
        )}
      </IonContent>
    </IonPage>
  );
}

export default Detail;
