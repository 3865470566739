import React, { useState } from 'react';

import { deleteAthlete } from '../../helpers/api';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonToolbar,
  IonList,
  IonInput,
  IonText,
  IonButton,
  IonTextarea,
  IonTitle,
  useIonRouter,
} from '@ionic/react';

export const EditAthlete = ({
  setEditIsOpen,
  user,
  currentUserId,
  handleEditAthlete,
  handleToast,
}) => {
  const router = useIonRouter();
  const [birthDate, setBirthDate] = useState(user.birthDate);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [location, setLocation] = useState(user.location);
  const [email, setEmail] = useState(user.email);
  const [cellPhone, setCellPhone] = useState(user.cellPhone);
  const [note, setNote] = useState(user.note);
  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteAthlete = () => {
    deleteAthlete(currentUserId, user.id);
    handleToast({
      message: `Successfully deleted ${firstName ? firstName : 'Athlete'} ${
        lastName ? lastName : ''
      }`,
    });
    router.push('/athletes', 'forward', 'push');
    setEditIsOpen(false);
  };

  const handleSave = () => {
    handleEditAthlete({
      note,
      id: user.id,
      avatar: user.avatar,
      email,
      cellPhone,
      firstName,
      lastName,
      location,
      birthDate,
      workouts: user?.workouts || {},
    });
    setEditIsOpen(false);
  };

  return (
    <>
      <IonHeader mode='ios'>
        <IonToolbar>
          <IonTitle>
            {user.firstName} {user.lastName}
          </IonTitle>
          <IonButtons slot='start'>
            <IonButton color='medium' onClick={() => setEditIsOpen(false)}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton color='primary' onClick={() => handleSave()}>
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <div className='add-workout'>
          <h1>Edit Athlete</h1>
          <IonList className='workouts'>
            <IonItem>
              <IonLabel>Email</IonLabel>
              <IonInput
                autocapitalize='true'
                inputmode='email'
                className='wider'
                value={email}
                placeholder=''
                type='email'
                onIonChange={(e) => setEmail(e.target.value)}
              />
            </IonItem>
            <IonItem>
              <IonLabel>Cellphone</IonLabel>
              <IonInput
                debounce={500}
                inputmode='tel'
                autocapitalize='true'
                className='wider'
                value={cellPhone}
                placeholder=''
                onIonChange={(e) => setCellPhone(e.target.value)}
              />
            </IonItem>
            <IonItem>
              <IonLabel>First Name</IonLabel>
              <IonInput
                debounce={500}
                className='wider'
                autocapitalize='true'
                value={firstName}
                placeholder=''
                onIonChange={(e) => setFirstName(e.target.value)}
              />
            </IonItem>

            <IonItem>
              <IonLabel>Last Name</IonLabel>
              <IonInput
                debounce={500}
                className='wider'
                value={lastName}
                autocapitalize='true'
                placeholder=''
                onIonChange={(e) => setLastName(e.target.value)}
              />
            </IonItem>

            <IonItem>
              <IonLabel>Location</IonLabel>
              <IonInput
                debounce={500}
                className='wider'
                autocapitalize='true'
                value={location}
                placeholder=''
                onIonChange={(e) => setLocation(e.target.value)}
              />
            </IonItem>
          </IonList>

          <IonList>
            <IonItem>
              <IonLabel>Birthdate</IonLabel>
              <IonInput
                value={birthDate}
                onIonChange={(e) => setBirthDate(e.target.value)}
                placeholder='mm/dd/yyyy'
              ></IonInput>
            </IonItem>
          </IonList>
          <h1>Notes</h1>
          <IonList className='note'>
            <IonItem>
              <IonTextarea
                autocapitalize='true'
                value={note}
                rows='5'
                placeholder='Add a note...'
                onIonChange={(e) => setNote(e.target.value)}
              ></IonTextarea>
            </IonItem>
          </IonList>
          <br />
          {!showDelete && (
            <IonButton
              color='light'
              expand='full'
              onClick={() => setShowDelete(true)}
            >
              <IonText color='danger'>Delete User</IonText>
            </IonButton>
          )}
          {showDelete && (
            <div className='flex'>
              <IonButton color='dark' onClick={() => setShowDelete(false)}>
                Cancel
              </IonButton>
              <IonButton color='danger' onClick={() => handleDeleteAthlete()}>
                Confirm Delete
              </IonButton>
            </div>
          )}
        </div>
      </IonContent>
    </>
  );
};

export default EditAthlete;
