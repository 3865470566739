// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { Capacitor } from '@capacitor/core';
import {
  GoogleAuthProvider,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDS7ozYM8bOjae4Ae5dxB74CAhYXlrdblQ',
  authDomain: 'fitverse-c26d0.firebaseapp.com',
  databaseURL: 'https://fitverse-c26d0-default-rtdb.firebaseio.com',
  projectId: 'fitverse-c26d0',
  storageBucket: 'fitverse-c26d0.appspot.com',
  messagingSenderId: '967977617958',
  appId: '1:967977617958:web:29172130c3c8bc9f23f4e7',
  measurementId: 'G-14QBG093VJ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
if (Capacitor.isNativePlatform) {
  initializeAuth(app, {
    persistence: indexedDBLocalPersistence,
  });
}

export const analytics = getAnalytics(app);
export const fire = app;
export const fireStorage = getStorage(app);
export const fireAuth = getAuth(app);
export const provider = new GoogleAuthProvider();
