import React, { useState } from 'react';
import { IonItem, IonLabel, IonButton, IonIcon } from '@ionic/react';
import { chevronDown, trash, send, albums, create } from 'ionicons/icons';
import './ListItemWorkout.scss';
import {
  getFormattedDate,
  isToday,
  handleLink,
  formatSecondsToMins,
} from '../../helpers/helpers';
import { addSharedWorkout } from '../../helpers/api';
import { v4 as uuidv4 } from 'uuid';

const ListItemWorkout = ({
  date,
  item,
  note,
  cellPhone,
  firstName,
  lastName,
  trainer,
  handleDeleteWorkoutFromAthletesList,
  workout,
  handleCloneWorkout,
  expanded,
  data,
  handleEditWorkout,
}) => {
  const [toggled, setToggled] = useState(expanded || false);
  const [showDelete, setShowDelete] = useState(false);

  const share = (item) => {
    const btoaedWorkout = btoa(
      JSON.stringify({
        workouts: item,
        trainer,
        date,
        note,
        name: `${firstName}${lastName ? ` ${lastName.slice(0, 2)}` : ''}`,
      })
    );

    //FB save sharedWorkout
    const addedWorkout = addSharedWorkout(workout.id, btoaedWorkout);

    //sms the link
    handleLink(
      'sms',
      cellPhone,
      `https://fitversestudio.com/workout?a=${workout.id}`
      /* `${window.location.origin}/workout?a=${workout.id}` */
    );
  };

  return (
    <div className={`item-list-workout ${toggled ? 'expanded' : ''}`}>
      <IonItem lines="full">
        <IonLabel
          className={expanded ? 'no-actions' : ''}
          onClick={() => {
            !expanded && setToggled(!toggled);
          }}
        >
          <div>
            <h2>
              <span className="faded">{getFormattedDate(date)} </span>
              {isToday(date) ? <ion-text color="success">Today </ion-text> : ''}
            </h2>
            {data?.map((d, index) => (
              <div className="section" key={d.type + index}>
                <h3>
                  <span>
                    {d.type}{' '}
                    {/* {d['total time'] &&
                      `for ${formatSecondsToMins(+d['total time'])}`} */}
                    {d['total time'] && `for ${+d['total time']} mins`}
                  </span>
                </h3>
                {d.instructions && <p>{d.instructions}</p>}
                <div>
                  {d?.data?.map((i) => (
                    <div key={i.exercise + i.index}>
                      <b>{i.exercise}</b> {i.weight && `${i.weight} lbs `}
                      {i.reps && `for ${i.reps} reps `}
                      {i.time && `for ${i.time} mins`}
                      {i.repeat && ` x ${i.repeat} sets `}
                      {i.calories && `for ${i.calories} calories `}
                      {i.targetTime &&
                        `for target time of ${i.targetTime} mins`}
                    </div>
                  ))}
                </div>
              </div>
            ))}

            {note && <p className="faded">Note: {note}</p>}
          </div>
        </IonLabel>
        {!expanded && (
          <>
            <IonIcon
              onClick={() => {
                expanded && setToggled(!toggled);
              }}
              slot="end"
              className="chevron"
              icon={chevronDown}
            ></IonIcon>
            {!showDelete && (
              <div className="actions">
                {cellPhone && (
                  <IonButton
                    size="default"
                    color="light"
                    onClick={() => share(item)}
                  >
                    <IonIcon slot="icon-only" icon={send}></IonIcon>
                  </IonButton>
                )}

                <IonButton
                  size="default"
                  className="icon-only"
                  color="light"
                  title="clone"
                  onClick={() =>
                    handleEditWorkout({
                      data: item,
                      date: new Date().toISOString(),
                      id: uuidv4(),
                      note,
                      isClone: true,
                    })
                  }
                >
                  <IonIcon slot="icon-only" icon={albums}></IonIcon>
                </IonButton>

                <IonButton
                  size="default"
                  className="icon-only"
                  color="light"
                  title="edit"
                  onClick={() =>
                    handleEditWorkout({
                      data: item,
                      date,
                      id: workout.id,
                      note,
                    })
                  }
                >
                  <IonIcon slot="icon-only" icon={create}></IonIcon>
                </IonButton>

                <IonButton
                  size="default"
                  className="icon-only"
                  color="light"
                  onClick={() => setShowDelete(true)}
                >
                  <IonIcon slot="icon-only" icon={trash}></IonIcon>
                </IonButton>
              </div>
            )}
            {showDelete && (
              <div className="actions delete">
                <IonButton color="dark" onClick={() => setShowDelete(false)}>
                  Cancel
                </IonButton>
                <IonButton
                  color="danger"
                  onClick={() => handleDeleteWorkoutFromAthletesList(workout)}
                >
                  Confirm Delete
                </IonButton>
              </div>
            )}
          </>
        )}
      </IonItem>
    </div>
  );
};

export default ListItemWorkout;
