import React from 'react';
import {
  IonPage,
  IonContent,
  useIonRouter,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonToggle,
  IonRadio,
  IonCheckbox,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
} from '@ionic/react';
import './Profile.scss';

function Profile({ currentUser }) {
  const router = useIonRouter();
  return (
    <div className="profile">
      <IonPage>
        <IonContent className="ion-padding-horizontal">
          <h1>Profile</h1>

          <ion-list>
            <ion-item>
              <ion-label>Email</ion-label>
              <ion-note slot="end">{currentUser.email}</ion-note>
            </ion-item>

            <ion-item
              color="danger"
              button
              onClick={() => router.push('/logout', 'forward', 'push')}
            >
              <ion-label>Logout</ion-label>
            </ion-item>
          </ion-list>
        </IonContent>
      </IonPage>
    </div>
  );
}

export default Profile;
