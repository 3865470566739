import { useIonRouter } from '@ionic/react';
import { getAuth, signOut } from 'firebase/auth';

function Logout({ setCurrentUser, setIsLoggedIn }) {
  const router = useIonRouter();
  const auth = getAuth();

  const signOutUser = async () => {
    // add try catch if you want
    signOut(auth);
    setTimeout(() => {
      router.push('/login', 'forward', 'push');
      setCurrentUser(null);
      setIsLoggedIn(false);
    }, 1000);
  };

  signOutUser();

  return <div></div>;
}

export default Logout;
