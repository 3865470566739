import React, { useState, useEffect } from 'react';
import ListItemWorkout from '../../components/ListItemWorkout/ListItemWorkout';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonList,
  IonSearchbar,
  IonButton,
  IonTitle,
  IonItem,
} from '@ionic/react';

export const SearchWorkouts = ({
  setSearchIsOpen,
  user,
  athleteId,
  trainer,
  handleCloneWorkout,
}) => {
  const workoutArray = user.workouts ? Object.values(user?.workouts) : [];
  const [workouts, setWorkouts] = useState(workoutArray);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setTimeout(() => {
      let elem = document.querySelector('.search-autofocus input');
      if (elem) {
        elem.focus();
      }
    }, 500);
  }, []);

  const isHidden = (w) => {
    const stringified = JSON.stringify(w.data)?.toLowerCase();
    const contains = stringified.includes(searchQuery?.toLowerCase());
    if (contains.length > 0) return false;
    return true;
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {user?.firstName} {user?.lastName}
          </IonTitle>
          <IonButtons slot="start">
            <IonButton
              color="medium"
              onClick={() => {
                setSearchIsOpen(false);
              }}
            >
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div>
          {workoutArray?.length > 0 && (
            <>
              <IonSearchbar
                className="search-autofocus"
                value={searchQuery}
                onIonChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Workouts..."
                autoFocus={true}
                debounce={500}
                setFocus={true}
              />

              <IonList lines="full">
                {workouts
                  ?.sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                  })
                  .map((w) => (
                    <div className={isHidden(w) ? 'hidden' : ''}>
                      <ListItemWorkout
                        key={w.date}
                        date={w.date}
                        athleteId={athleteId}
                        note={w.note}
                        cellPhone={user.cellPhone}
                        email={user.email}
                        firstName={user.firstName}
                        trainer={trainer}
                        data={w.data}
                        expanded={true}
                        type={w.type}
                        totalTime={w.totalTime ? +w.totalTime : null}
                        instructions={w.instructions}
                      />
                    </div>
                  ))}
              </IonList>
            </>
          )}

          {workoutArray?.length === 0 && (
            <div className="placeholder">
              <h2>No workouts here...</h2>
              <h3>Get your athlete to work!</h3>
              <IonButton onClick={() => setSearchIsOpen(false)}>
                Back to Athlete
              </IonButton>
            </div>
          )}
        </div>
      </IonContent>
    </>
  );
};

export default SearchWorkouts;
